@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "IBM Plex Sans Thai", system-ui, sans-serif;
  }

  @keyframes grayscale {
    0% {
      filter: grayscale(100%);
    }
    100% {
      filter: grayscale(0%);
    }
  }

  @font-face {
    font-family: "GoodGirl";
    src: url("../public/fonts/GoodGirl-Regular.otf");
  }

  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    box-shadow: 0 0 0 1px #f72db1;
    border-color: #7222f0;
  }
}

@layer components {
  .custom-gap > span:nth-child(-1n + 1) {
    padding-left: 4px;
  }
  .custom-gap > span:nth-child(-1n + 4) {
    margin-left: -6px;
  }
  .custom-input-range > input {
    position: relative;
  }
  .custom-input-range > input[type="range"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    height: 8px;
    width: 100%;
    border-radius: 4px;
    background: linear-gradient(to right, #eaecf0 0%, #eaecf0 0%);
  }

  .custom-input-range.--w1-5 > input[type="range"] {
    background: linear-gradient(to right, #7222f0 0%, #f72db1 20%, #eaecf0 20%);
  }
  .custom-input-range.--w2-5 > input[type="range"] {
    background: linear-gradient(to right, #7222f0 0%, #f72db1 40%, #eaecf0 40%);
  }
  .custom-input-range.--w3-5 > input[type="range"] {
    background: linear-gradient(to right, #7222f0 0%, #f72db1 60%, #eaecf0 60%);
  }
  .custom-input-range.--w4-5 > input[type="range"] {
    background: linear-gradient(to right, #7222f0 0%, #f72db1 80%, #eaecf0 80%);
  }
  .custom-input-range.--w5-5 > input[type="range"] {
    background: linear-gradient(to right, #7222f0 0%, #f72db1 100%);
  }

  .custom-input-range > input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #f72db1, #7222f0);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    border-radius: 8px;
  }

  .custom-input-range > input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #f72db1, #7222f0);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    border-radius: 8px;
  }
}
